body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;

}



.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
}

.success {
  color: green;
  background: greenyellow;
  border: 1px solid green;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
}

/* .noBorder {
  border: 1px solid gray;
} */


.pageL {

  width: 98%;
  display: block;
  size: landscape;
  orientation: landscape;
  font-family: Arial;
  font-size: 12pt;
  margin-top: 1cm;
  margin-bottom: 1cm;
  margin-left: auto;
  margin-right: auto;
  page-break-after: always;

}

.logoImage {
  height: 3cm;

}

.campusHeader {
  color: #00076F;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 6pt;
  text-align: left;


}




.HomeTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: red;
}


.buy-container {
  overflow-y: hidden;
  width: 100vw;
}



.lora {
  font-family: "Lora-SemiBold", serif;
  font-optical-sizing: auto;

}

.Typography {
  font-family: 'Courier New', Courier, monospace;
}


.buttonIllum {
  background-color: transparent;
  color: white;
  border-radius: 5px;
}

.buttonIllum:hover span {
  display: none
}

.buttonIllum:hover:before {
  content: "Brighten and Optimum"
}

@media print {


  #page {
    border: none;


  }

  #printButton {
    display: none;
  }

  .printButton {
    display: none;
  }

  #noPrint {
    display: none;
  }

  .noBorder {
    border: none;
  }

  a[href]:after {
    content: none !important;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: auto;

  }

  td {
    page-break-inside: avoid;

  }
}




@page {

  margin-right: 2.2cm;
  margin-left: 2.2cm;
  margin-bottom: 1px;

}

#loginPage {
  background-color: red;
}